import React from "react";
import styled from "styled-components";
import { colors } from "utils";

const MainButton = styled.button`
  display: ${({ display = "inline-block" }) => display};
  text-decoration: ${({ decoration = "none" }) => decoration};
  border: ${({ border = "none" }) => border};
  background: ${({ background }) => background};
  color: ${({ color = colors.NEUTRAL }) => color};
  position: ${({ position }) => position};
  top: ${({ top }) => top};
  right: ${({ right }) => right};
  bottom: ${({ bottom }) => bottom};
  left: ${({ left }) => left};
  width: ${({ width }) => width};
  border-radius: ${({ radius = "4px" }) => radius};
  cursor: ${({ cursor = "pointer" }) => cursor};
  padding: ${({ padding = "16px 32px" }) => padding};
  font-size: ${({ size = "1rem" }) => size};
  text-align: ${({ textAlign = "center" }) => textAlign};
  white-space: ${({ whiteSpace }) => whiteSpace};
  line-height: ${({ lineHeight = "1em" }) => lineHeight};
  opacity: ${({ opacity = "1" }) => opacity};
  transition-duration: 0.3s;
  margin: ${({ margin }) => margin};

  &:focus {
    outline: none;
  }
  &:hover {
    opacity: 0.8;
  }
  &.b {
    font-weight: bold;
  }
  &.primary {
    background-color: ${colors.GOLD_100};
    color: ${colors.WHITE};
  }
  &.secondary {
    padding: 10px 20px !important;
  }
  &.red {
    background-color: ${colors.RED_400};
  }
  &.blue {
    background-color: ${colors.BLUE_100};
    color: ${colors.WHITE};
    &:hover {
      color: ${colors.BLUE_100};
      background-color: ${colors.WHITE};
    }
  }
  &.green {
    background-color: ${colors.GREEN_100};
  }
  &.short {
    padding: 8px 32px;
  }
  &.link {
    background: transparent;
    box-shadow: none;
    text-decoration: underline;
    &: hover {
      color: ${colors.BLUE_85};
    }
  }

  &.outline {
    background: none;
    color: ${colors.NEUTRAL};
    border: 1px solid ${colors.NEUTRAL};
    &.BASE {
      color: ${colors.BASE};
      border: 1px solid ${colors.BASE};
    }
    &.primary {
      color: ${colors.GOLD_100};
      border: 1px solid ${colors.GOLD_100};
    }
    &.blue {
      color: ${colors.BLUE_100};
      border: 1px solid ${colors.BLUE_100};
    }
    &.guide {
      padding: 8px 32px;
      margin-right: 16px;
    }
    &.short {
      padding: 8px 32px;
    }
    &.skeleton {
      padding: 12px 62px;
      border: 1px solid ${colors.NEUTRAL_50};
    }
    &.disabled {
      cursor: default;
      border: 1px solid ${colors.NEUTRAL_50};
      color: ${colors.NEUTRAL_50};
    }

    border: ${({ border }) => border};
  }

  &.plain {
    background: none;
    border: none;

    &.primary {
      color: ${colors.GOLD_100};
    }
  }
`;

const Button = ({ className, kind, ...props }) => (
  <MainButton className={`${kind} ${className}`} {...props} />
);

export default Button;
