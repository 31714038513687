import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { auth0EventTypes, subscriptionTypes } from "consts";
import { colors, media, matchMonth, search } from "utils";
import { format } from "date-fns";
import { navigate } from "@reach/router";
import { ButtonIcon, Button, Flex, Text, NavLink } from "components";
import { useForm } from "react-hook-form";
import { updateFuneralHome } from "api/funeral-home";
import { useToken } from "providers/AuthProvider";
import { useOnUpdateEffect } from "hooks";

import { FcComboChart, FcViewDetails } from "react-icons/fc";
import { BsTrashFill, BsTrash } from "react-icons/bs";
import { TiArrowSync } from "react-icons/ti";
import { MdCancel, MdPreview } from "react-icons/md";
import { HiOutlineClipboardCopy } from "react-icons/hi";
import { AiOutlineUserDelete, AiOutlinePrinter } from "react-icons/ai";

import us from "date-fns/locale/en-US";

import Tooltip from "rc-tooltip";
import "rc-tooltip/assets/bootstrap_white.css";

const Input = styled.input`
  width: 100%;
  border: none;
  outline: none;
  padding: 4px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: left;
  font-weight: inherit;

  &:hover {
    border-left: 1px solid ${colors.GOLD};
  }

  &.active {
    color: green;
    font-weight: 600;
  }
  &.onEdit {
    font-weight: 600;
  }
`;

const Select = styled.select`
  width: 100%;
  border: none;
  outline: none;
  padding: 4px;
  text-align: left;
  font-weight: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";

  &:hover {
    border-left: 1px solid ${colors.GOLD};
  }

  &::-ms-expand {
    display: none;
  }
  &.active {
    color: green;
    font-weight: 600;
  }
  &.onEdit {
    font-weight: 600;
  }
`;

const InputContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  z-index: 999;

  ${media.desktop`
      margin:0;
  `}
`;

const ButtonContainer = styled(Flex)`
  min-width: 50px;
  margin-left: -82px;
  z-index: 9999;

  ${media.desktop`
      margin-left: -28px;
  `}
`;

const Ellipses = styled.p`
  width: 100%;
  padding: 4px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: left;
  font-weight: inherit;
`;

// ===================== INPUT EDITABLE CELL =====================

const InputEditableCell = ({
  value: initialValue, // cell value
  updateValues, // setUpdatedUser
  details, // updatedUser in Users
  column: { Header, id }, // field name e.g. fh_id
  row: { values, index }, // row number where update button will show
  reset: resetForm,
  patch,
  withButtons, // renders update and cancel button with input
}) => {
  const [value, setValue] = useState(initialValue); // what renders on the table
  const [isOnEdit, setIsOnEdit] = useState(false);

  // keeps table value always up-to-date
  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const onChange = (e) => {
    setValue(e.target.value);
  };

  useOnUpdateEffect(() => {
    if (value !== (initialValue || "")) {
      updateValues({ ...details, [id]: value, row_id: index });
    } else {
      updateValues("");
    }
  }, [value]);

  return (
    <InputContainer>
      {(Header === "Commission" || Header === "Product Amount") && (
        <span>$</span>
      )}
      <Tooltip
        placement="bottom"
        overlay={<span>Click to edit</span>}
        arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
      >
        <Input
          className={details && index === details.row_id && "onEdit"}
          value={value}
          onChange={onChange}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            setIsOnEdit(!isOnEdit);
          }}
          readonly={isOnEdit ? false : true}
        />
      </Tooltip>
      {withButtons && (
        <Flex minWidth="40px">
          <Tooltip
            placement="bottom"
            overlay={<span>Update</span>}
            arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
          >
            <ButtonIcon
              onClick={(e) => {
                e.stopPropagation();
                patch(values);
              }}
              margin="0 2px"
              kind={
                details && details.row_id === index ? "default hover" : "hidden"
              }
              changeIcon={<TiArrowSync />}
              defaultIcon={<TiArrowSync />}
              color={colors.GREEN_100}
              size="1.5rem"
              padding="2px"
            />
          </Tooltip>
          <Tooltip
            placement="bottom"
            overlay={<span>Cancel</span>}
            arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
          >
            <ButtonIcon
              onClick={(e) => {
                e.stopPropagation();
                setValue(initialValue || "");
                updateValues("");
                resetForm();
              }}
              margin="0 2px"
              kind={
                details && details.row_id === index ? "default hover" : "hidden"
              }
              changeIcon={<MdCancel />}
              defaultIcon={<MdCancel />}
              color={colors.RED_200}
              size="1.5rem"
              padding="2px"
            />
          </Tooltip>
        </Flex>
      )}
    </InputContainer>
  );
};

// ===================== END INPUT EDITABLE CELL =====================

// ===================== SELECT CELL =====================

const SelectCell = ({
  value: initialValue, // cell value
  updateValues, // setUpdatedUser
  details, // updatedUser in Users
  column: { Header, id }, // field name e.g. fh_id
  row: { index }, // row number where update button will show
  selectType,
}) => {
  const [value, setValue] = useState(initialValue); // what renders on the table
  const [isOnEdit, setIsnEdit] = useState(false);

  // keeps table value always up-to-date
  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const onChange = (e) => {
    setValue(e.target.value);
    updateValues({ ...details, [id]: e.target.value, row_id: index });
  };

  const selectOptions = {
    users: [
      { optionValue: "user", display: "User" },
      { optionValue: "superAdmin", display: "Super Admin" },
      { optionValue: "corporationAdmin", display: "Corporation Admin" },
      {
        optionValue: "chiefCorporationAdmin",
        display: "Chief Corporation Admin",
      },
    ],
  };

  return (
    <InputContainer>
      {(Header === "Commission" || Header === "Product Amount") && (
        <span>$</span>
      )}
      <Tooltip
        placement="bottom"
        overlay={<span>Click to select an option.</span>}
        arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
      >
        <Select
          className={details && index === details.row_id && "onEdit"}
          value={value}
          onChange={onChange}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            setIsnEdit(!isOnEdit);
          }}
          readonly={isOnEdit ? false : true}
        >
          {selectOptions[selectType].map(({ optionValue, display }) => {
            return (
              <option key={optionValue} value={optionValue}>
                {display}
              </option>
            );
          })}
        </Select>
      </Tooltip>
    </InputContainer>
  );
};

// ===================== END SELECT CELL =====================

const IconWrapper = styled.div`
  background: transparent;
  border: 1px solid ${colors.WHITE};
  border-radius: 50%;
  &:hover {
    cursor: pointer;
    border: 1px solid ${colors.NEUTRAL_05};
  }
`;

const EditableCell = ({ value, fh_id }) => {
  const [success, setSuccess] = useState(false);
  const [token] = useToken();
  const [responseSubscriptions, setResponseSubscriptions] = useState(null);
  const { handleSubmit, register, setValue, watch, reset } = useForm({
    defaultValues: {
      selectedSubscriptions: responseSubscriptions || value,
    },
  });
  // Available subscription types
  const dictionary = ["market_share", "fhls", "local_ad", "lpro_business"];

  const selectedSubs = watch("selectedSubscriptions", value);

  const updateSubscriptions = async ({ selectedSubscriptions }) => {
    const res = await updateFuneralHome(
      {
        fh_id,
        subscriptions: selectedSubscriptions,
      },
      token
    );
    if (res) {
      setSuccess(true);
      setResponseSubscriptions(res.subscriptions);
    }
  };

  const triggerReset = (e) => {
    e.preventDefault();
    // If subscription has been updated, reset to update response, else use default values
    responseSubscriptions
      ? setValue("selectedSubscriptions", responseSubscriptions)
      : reset();
  };

  const compareArrays = () => {
    const array1 = selectedSubs.sort();
    const array2 = responseSubscriptions?.sort() || value.sort();
    return (
      array1.length === array2.length &&
      array1.every((value, index) => value === array2[index])
    );
  };

  return (
    <form onSubmit={handleSubmit(updateSubscriptions)}>
      <Flex column align="flex-start">
        {success && (
          <Button margin="4px 0 0" padding="4px 8px" disabled kind="green">
            Success!
          </Button>
        )}
        {dictionary.map((item) => {
          const renderText = search(subscriptionTypes, item, "object").label;

          return (
            <Flex key={item}>
              <input
                name="selectedSubscriptions"
                type="checkbox"
                value={item}
                ref={register()}
                onChange={() => {
                  setValue(item);
                  setSuccess(false);
                }}
              />
              <Text size="14px">&nbsp;&nbsp;{renderText}</Text>
            </Flex>
          );
        })}
      </Flex>
      {!compareArrays() && (
        <Flex margin="4px 0 0">
          <Button padding="4px 8px" margin="0 8px 0 0" type="submit">
            Update subscriptions
          </Button>
          <Button padding="4px 8px" onClick={triggerReset}>
            Cancel
          </Button>
        </Flex>
      )}
    </form>
  );
};

export const hiddenColumns = {
  funeralHomes: [],
  obitVolumeDetails: [],
  funeralHomeCompetitors: [],
  funeralHomeFlowers: [],
  localAd: [],
  fhSpotlightFlowers: [],
  funeralHomeEstimates: [],
  superAdminEstimates: [],
  superAdminLeadPartners: [],
  superAdminCorporations: [],
  leadPartnerEstimates: [],
  fhSpotlightObits: [],
  chiefCorpOverview: [],
  users: [
    { Header: "ID", accessor: "user_id", id: "user_id" },
    { Header: "Auth0", accessor: "auth0_id", id: "auth0_id" },
  ],
  auth0: [],
  auth0UserLogs: [],
};

const sharedEstimateColumns = [
  {
    Header: "Print",
    id: "show_details",
    width: 80,
    minWidth: 80,
    Cell: ({ row: { original, index }, showDetails }) => {
      return (
        <Flex minWidth="80px">
          <Tooltip
            placement="bottom"
            overlay={<span>View Details</span>}
            arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
          >
            <ButtonIcon
              onClick={(e) => {
                e.stopPropagation();
                showDetails({
                  row_id: index,
                  data: original,
                });
              }}
              padding="10px 12px"
              margin="0 4px"
              kind="transparent"
              defaultIcon={<AiOutlinePrinter />}
              size="2rem"
            />
          </Tooltip>
        </Flex>
      );
    },
  },
  {
    Header: "",
    id: "estimate_number",
    width: 40,
    minWidth: 40,
    Cell: ({ data, row: { id } }) => <p>{data.length - parseInt(id)}</p>,
  },
  {
    Header: "Short Id",
    accessor: "short_id",
    width: 300,
    minWidth: 300,
  },
  {
    Header: "Date",
    accessor: "created_at",
    id: "created_at",
    minWidth: 20,
    Cell: ({ value }) => <p>{format(new Date(value), "PP")}</p>,
  },
  {
    Header: "First Name",
    accessor: "guest_first_name",
    id: "guest_first_name",
    minWidth: 40,
  },
  {
    Header: "Last Name",
    accessor: "guest_last_name",
    id: "guest_last_name",
    minWidth: 40,
  },
  {
    Header: "Phone",
    accessor: "guest_phone",
    id: "guest_phone",
    minWidth: 40,
  },
  {
    Header: "State",
    accessor: "guest_state",
    id: "guest_state",
    minWidth: 40,
  },

  {
    Header: "Zip",
    accessor: "guest_zip",
    id: "guest_zip",
    minWidth: 20,
  },
  {
    Header: "FH Name",
    accessor: "fh_name",
    minWidth: 40,
    Cell: ({ value, row: { original } }) => {
      return (
        <a href={original.fh_url} target="_blank" rel="noopener noreferrer">
          {value}
        </a>
      );
    },
  },
  {
    Header: "With Service?",
    accessor: "service",
    id: "service",
    minWidth: 50,
    Cell: ({ value }) => <p>{value ? "Yes" : "No"}</p>,
  },
  {
    Header: "Disposition",
    accessor: "disposition_type",
    id: "disposition_type",
    minWidth: 80,
  },
  {
    Header: "Estimate",
    accessor: "cost",
    id: "cost",
    minWidth: 90,
  },
];

export const columns = {
  auth0: [
    {
      Header: "Email",
      accessor: "email",
      minWidth: 300,
      width: 300,
      Cell: ({ value }) => {
        return <Ellipses> {value}</Ellipses>;
      },
    },
    {
      Header: () => <div style={{ textAlign: "center" }}>Last Login</div>,
      accessor: "last_login",
      minWidth: 100,
      width: 100,
      Cell: ({ value }) => {
        return <Ellipses> {value}</Ellipses>;
      },
    },
    {
      Header: "Client",
      accessor: "client_name",
      minWidth: 100,
      width: 100,
      Cell: ({ value }) => {
        return <Ellipses> {value}</Ellipses>;
      },
    },
    {
      Header: () => <div style={{ textAlign: "center" }}>Date Activated</div>,
      accessor: "created_at",
      minWidth: 100,
      width: 100,
      Cell: ({ value }) => {
        return value;
      },
    },
    {
      Header: () => <div style={{ textAlign: "center" }}>Login Count</div>,
      accessor: "login_count",
      minWidth: 75,
      width: 75,
      Cell: ({ value }) => {
        return value;
      },
    },
    {
      Header: "",
      id: "utils",
      align: "center",
      minWidth: 60,
      width: 60,
      Cell: ({ row: { original } }) => {
        return (
          <Button padding="10px">
            <NavLink
              key={`user-details/${original.auth0_id}`}
              to={`user-details/${original.auth0_id}`}
            >
              <Text kind="f8" whiteSpace="nowrap">
                User Logs
              </Text>
            </NavLink>
          </Button>
        );
      },
    },
  ],
  auth0UserLogs: [
    {
      Header: () => <div style={{ textAlign: "center" }}>Log ID</div>,
      accessor: "id",
      align: "center",
      width: 100,
      minWidth: 100,
      disableSortBy: true,
      Cell: ({ value }) => {
        return (
          <Tooltip
            placement="right"
            overlay={<span>Copy Log ID: {value}</span>}
            arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
          >
            <IconWrapper
              onClick={(e) => {
                e.stopPropagation();
                navigator.clipboard.writeText(value);
              }}
            >
              <HiOutlineClipboardCopy style={{ fontSize: "2rem" }} />
            </IconWrapper>
          </Tooltip>
        );
      },
    },
    {
      Header: "Date",
      accessor: "date",
      minWidth: 100,
      width: 100,
      Cell: ({ value }) => {
        return <Ellipses> {value}</Ellipses>;
      },
    },
    {
      Header: "Type",
      accessor: "type",
      minWidth: 100,
      width: 100,
      Cell: ({ value }) => {
        return (
          <Ellipses>
            {" "}
            {auth0EventTypes[value]
              ? auth0EventTypes[value]
              : "Unknown Event Type"}
          </Ellipses>
        );
      },
    },
    {
      Header: () => <div style={{ textAlign: "center" }}>User Agent</div>,
      accessor: "user_agent",
      minWidth: 250,
      width: 250,
      Cell: ({ value }) => {
        return <Ellipses> {value}</Ellipses>;
      },
    },
    {
      Header: "Client",
      accessor: "client_name",
      minWidth: 100,
      width: 100,
      Cell: ({ value }) => {
        return <Ellipses> {value}</Ellipses>;
      },
    },
  ],

  users: [
    {
      Header: "Name",
      accessor: "name",
      minWidth: 200,
      Cell: InputEditableCell,
      // Cell: ({ value }) => {
      //   return <Ellipses> {value}</Ellipses>;
      // }, // Leaving here for when we the client wants to not make editable
    },
    {
      Header: "Email",
      accessor: "email",
      minWidth: 200,
      // Cell: InputEditableCell, // Leaving here for when we the client wants to make editable
      Cell: ({ value }) => {
        return <Ellipses> {value}</Ellipses>;
      },
    },
    {
      Header: "Type",
      accessor: "type",
      minWidth: 160,
      Cell: (prop) => {
        return prop.row.cells[4].value === null ? (
          <Ellipses> {prop.value}</Ellipses>
        ) : (
          SelectCell(prop)
        );
      },
    },
    {
      Header: "Linked Homes",
      accessor: "linkedHomes",
      id: "linkedHomes",
      Cell: ({ row: { original } }) => {
        return (
          <Tooltip
            placement="bottom"
            overlay={<span>Copy: Linked Funeral Homes</span>}
            arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
          >
            <Ellipses>
              <Text
                onClick={(e) => {
                  e.stopPropagation();
                  navigator.clipboard.writeText(original.linkedHomes);
                }}
              >
                {original.linkedHomes}
              </Text>
            </Ellipses>
          </Tooltip>
        );
      },
    },
    {
      Header: "",
      id: "utils",
      disableSortBy: true,
      minWidth: 40,
      align: "space-around",
      Cell: ({ row: { original, index }, patch, archive, details }) => {
        return (
          <>
            <ButtonContainer>
              <Tooltip
                placement="bottom"
                overlay={<span>Delete</span>}
                arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
              >
                <ButtonIcon
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    archive(original.user_id);
                  }}
                  padding="10px 12px"
                  margin="0 4px"
                  kind={"transparent"}
                  changeIcon={<AiOutlineUserDelete />}
                  defaultIcon={<AiOutlineUserDelete />}
                  color={colors.red}
                  size="2rem"
                />
              </Tooltip>
            </ButtonContainer>
            <ButtonContainer>
              <Tooltip
                placement="bottom"
                overlay={<span>Update</span>}
                arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
              >
                <ButtonIcon
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    patch(original);
                  }}
                  padding="10px 12px"
                  margin="0 4px"
                  kind={
                    details && details.row_id === index
                      ? "transparent"
                      : "hidden"
                  }
                  changeIcon={<TiArrowSync />}
                  defaultIcon={<TiArrowSync />}
                  color={colors.GREEN_100}
                  size="2rem"
                />
              </Tooltip>
            </ButtonContainer>
          </>
        );
      },
    },
  ],

  localAd: [
    {
      Header: "Location",
      accessor: "target_market",
      width: 250,
    },
    {
      Header: "Ads shown",
      accessor: "impressions",
      width: 10,
      Cell: ({ value }) => Number(value).toLocaleString(),
    },
    {
      Header: "Time in view",
      accessor: "total_time_hours",
      Cell: ({ value }) => Number(value).toFixed(2),
      width: 10,
    },
    {
      Header: "Avg time viewed",
      accessor: "viewable_seconds",
      Cell: ({ value }) => (value ? `${Number(value).toFixed(2)}` : 0),
      width: 10,
    },
    {
      Header: "Ad clicks",
      accessor: "clicks",
      width: 10,
    },
    {
      Header: "CTR",
      accessor: "average_ctr",
      Cell: ({ value }) => (value ? `${value.toFixed(2)}%` : 0),
      width: 20,
    },
  ],

  chiefCorpOverview: [
    {
      Header: "Group",
      accessor: "name",
      width: 300,
    },
    {
      Header: "Obits published",
      accessor: "obits",
      width: 100,
      Cell: ({ value }) => Number(value).toLocaleString(),
    },
    {
      Header: "Visitor views",
      accessor: "views",
      Cell: ({ value }) => Number(value).toLocaleString(),
      width: 100,
    },
    {
      Header: "People visited",
      accessor: "visits",
      Cell: ({ value }) => Number(value).toLocaleString(),
      width: 100,
    },
    {
      Header: "Floral sales",
      accessor: "flowers",
      width: 100,
    },
  ],

  funeralHomes: [
    {
      Header: "Id",
      accessor: "fh_id",
      id: "fh_id",
      width: 90,
      minWidth: 60,
      Cell: ({
        row: {
          values: { parent_fh_id },
        },
        value,
      }) => (
        <Tooltip
          placement="right"
          overlay={<span>{value}</span>}
          arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
        >
          {value === parent_fh_id ? (
            <Ellipses>
              <span role="img" style={{ color: "green" }}>
                ⦿&nbsp;&nbsp;
              </span>
              {value}
            </Ellipses>
          ) : (
            <Ellipses>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{value} </Ellipses>
          )}
        </Tooltip>
      ),
    },
    {
      Header: "Parent ID",
      accessor: "parent_fh_id",
      id: "parent_fh_id",
      width: 100,
      minWidth: 90,
      Cell: (props) =>
        props.value === props.fh_id ? (
          <span>{props.value}</span>
        ) : (
          <InputEditableCell {...props} withButtons />
        ),
    },
    {
      Header: "Name",
      accessor: "fh_display",
      id: "fh_display",
      width: 100,
      minWidth: 100,
    },
    {
      Header: "Subscriptions",
      accessor: "subscriptions",
      width: 130,
      minWidth: 130,
      Cell: ({
        value,
        row: {
          original: { fh_id, parent_fh_id },
        },
      }) =>
        fh_id === parent_fh_id ? (
          <EditableCell {...{ value }} {...{ fh_id }} />
        ) : (
          ""
        ),
    },
    {
      Header: "Date Created",
      accessor: "created_at",
      id: "created_at",
      width: 70,
      minWidth: 70,
      Cell: ({ value }) => format(new Date(value), "PP"),
    },
    {
      Header: "Date Signed",
      accessor: "date_signed",
      id: "date_signed",
      width: 70,
      minWidth: 70,
      Cell: ({ value }) => {
        const date = new Date(value);
        if (value) {
          return date.toLocaleDateString("en-US", {
            year: "numeric",
            month: "short",
            day: "numeric",
          });
        } else {
          return "-";
        }
      },
    },
    {
      Header: "View Report",
      id: "report",
      width: 70,
      minWidth: 70,
      Cell: ({
        row: {
          values: { fh_id, fh_display, parent_fh_id },
        },
      }) => (
        <IconWrapper
          onClick={() =>
            navigate(`/admin/funeral-homes/reports/${fh_id}`, {
              state: { isParent: fh_id === parent_fh_id, fh_name: fh_display },
            })
          }
        >
          <FcComboChart style={{ fontSize: "3rem", marginLeft: "8px" }} />
        </IconWrapper>
      ),
    },
    {
      Header: "Details",
      id: "details",
      width: 70,
      minWidth: 70,
      Cell: ({
        row: {
          original: { fh_uuid },
          values: { fh_id, fh_display, parent_fh_id },
        },
      }) => (
        <IconWrapper
          onClick={() =>
            navigate(`/admin/funeral-homes/details/${fh_uuid}`, {
              state: { isParent: fh_id === parent_fh_id, fh_name: fh_display },
            })
          }
        >
          <FcViewDetails style={{ fontSize: "3rem", marginLeft: "8px" }} />
        </IconWrapper>
      ),
    },
  ],

  obitVolumeDetails: [
    {
      Header: "Parent FH Id",
      accessor: "parent_fh_id",
      id: "parent_fh_id",
      minWidth: 40,
      Cell: InputEditableCell,
    },
    {
      Header: "Volume ID",
      accessor: "obit_volume_id",
      id: "obit_volume_id",
      minWidth: 70,
      Cell: ({ value }) => (
        <Tooltip
          placement="right"
          overlay={<span>{value}</span>}
          arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
        >
          <p>
            {" "}
            {value.slice(0, 4)}...{value.slice(-6)}{" "}
          </p>
        </Tooltip>
      ),
    },
    {
      Header: "Year",
      accessor: "year",
      id: "year",
      minWidth: 30,
    },
    {
      Header: "Month",
      accessor: "month",
      id: "month",
      minWidth: 50,
      Cell: ({ value }) => `${matchMonth(value)}`,
    },
    {
      Header: "Obituary Count",
      accessor: "obit_count",
      id: "obit_count",
      minWidth: 40,
      Cell: InputEditableCell,
    },
    {
      Header: "",
      id: "utils",
      disableSortBy: true,
      minWidth: 40,
      align: "center",
      Cell: ({ row: { values, index }, remove, patch, details }) => (
        <Flex minWidth="90px">
          <Tooltip
            placement="bottom"
            overlay={<span>Update</span>}
            arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
          >
            <ButtonIcon
              onClick={(e) => {
                e.stopPropagation();
                patch(values);
              }}
              padding="10px 12px"
              margin="0 4px"
              kind={
                details && details.row_id === index ? "transparent" : "hidden"
              }
              changeIcon={<TiArrowSync />}
              defaultIcon={<TiArrowSync />}
              color={colors.GREEN_100}
              size="2rem"
            />
          </Tooltip>
          <Tooltip
            placement="bottom"
            overlay={<span>Delete Obituary Entry</span>}
            arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
          >
            <ButtonIcon
              onClick={(e) => {
                e.stopPropagation();
                remove(values);
              }}
              padding="12px 14px"
              margin="0 4px"
              kind="transparent delete"
              changeIcon={<BsTrashFill />}
              defaultIcon={<BsTrash />}
              color={colors.BLUE}
              size="1.6rem"
            />
          </Tooltip>
        </Flex>
      ),
    },
  ],

  funeralHomeCompetitors: [
    {
      Header: "Mapping ID",
      accessor: "fh_mapping_id",
      id: "fh_mapping_id",
      minWidth: 400,
      Cell: ({ value }) => (
        <Tooltip
          placement="right"
          overlay={<span>{value}</span>}
          arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
        >
          <p>{value}</p>
        </Tooltip>
      ),
    },
    {
      Header: "Competitor ID",
      accessor: "competitor_fh_id",
      id: "competitor_fh_id",
      minWidth: 30,
    },
    {
      Header: "Name",
      accessor: "fh_display",
      id: "fh_display",
      minWidth: 80,
    },
    {
      Header: "",
      id: "utils",
      disableSortBy: true,
      width: 40,
      align: "center",
      Cell: ({ row: { values }, remove }) => (
        <Flex minWidth="90px">
          <Tooltip
            placement="bottom"
            overlay={<span>Delete Competitor</span>}
            arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
          >
            <ButtonIcon
              onClick={() => remove(values)}
              padding="12px 14px"
              margin="0 4px"
              kind="transparent delete"
              changeIcon={<BsTrashFill />}
              defaultIcon={<BsTrash />}
              color={colors.BLUE}
              size="1.6rem"
            />
          </Tooltip>
        </Flex>
      ),
    },
  ],

  funeralHomeFlowers: [
    {
      Header: "FH ID",
      accessor: "fh_id",
      id: "fh_id",
    },
    {
      Header: "Year",
      accessor: "year",
      id: "year",
      minWidth: 90,
    },
    {
      Header: "Month",
      accessor: "month",
      id: "month",
      minWidth: 90,
      Cell: ({ value }) => us.localize.month(value - 1),
    },
    {
      Header: "Orders",
      accessor: "orders",
      id: "orders",
      minWidth: 90,
    },
  ],
  fhSpotlightFlowers: [
    {
      Header: "Flowers ID",
      accessor: "fhls_flowers_id",
      id: "fhls_flowers_id",
      minWidth: 70,
      Cell: ({ value }) => (
        <Tooltip
          placement="right"
          overlay={<span>{value}</span>}
          arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
        >
          <p>
            {" "}
            {value.slice(0, 4)}...{value.slice(-6)}{" "}
          </p>
        </Tooltip>
      ),
    },
    {
      Header: "Year",
      accessor: "year",
      id: "year",
      minWidth: 30,
    },
    {
      Header: "Month",
      accessor: "month",
      id: "month",
      minWidth: 50,
      Cell: ({ value }) => `${matchMonth(value)}`,
    },
    {
      Header: "Flower Count",
      accessor: "flower_count",
      id: "flower_count",
      minWidth: 50,
      Cell: InputEditableCell,
    },
    {
      Header: "",
      id: "utils",
      disableSortBy: true,
      minWidth: 40,
      align: "center",
      Cell: ({ row: { values, index }, remove, patch, details }) => (
        <Flex minWidth="90px">
          <Tooltip
            placement="bottom"
            overlay={<span>Update</span>}
            arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
          >
            <ButtonIcon
              onClick={(e) => {
                e.stopPropagation();
                patch(values);
              }}
              padding="10px 12px"
              margin="0 4px"
              kind={
                details && details.row_id === index ? "transparent" : "hidden"
              }
              changeIcon={<TiArrowSync />}
              defaultIcon={<TiArrowSync />}
              color={colors.GREEN_100}
              size="2rem"
            />
          </Tooltip>
          <Tooltip
            placement="bottom"
            overlay={<span>Delete Flower Entry</span>}
            arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
          >
            <ButtonIcon
              onClick={(e) => {
                e.stopPropagation();
                remove(values);
              }}
              padding="12px 14px"
              margin="0 4px"
              kind="transparent delete"
              changeIcon={<BsTrashFill />}
              defaultIcon={<BsTrash />}
              color={colors.BLUE}
              size="1.6rem"
            />
          </Tooltip>
        </Flex>
      ),
    },
  ],
  funeralHomeEstimates: [
    {
      Header: "Print",
      id: "show_details",
      width: 80,
      minWidth: 80,
      Cell: ({ row: { original, index }, showDetails }) => {
        return (
          <Flex minWidth="80px">
            <Tooltip
              placement="bottom"
              overlay={<span>View Details</span>}
              arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
            >
              <ButtonIcon
                onClick={(e) => {
                  e.stopPropagation();
                  showDetails({
                    row_id: index,
                    data: original,
                  });
                }}
                padding="10px 12px"
                margin="0 4px"
                kind="transparent"
                defaultIcon={<AiOutlinePrinter />}
                size="2rem"
              />
            </Tooltip>
          </Flex>
        );
      },
    },
    {
      Header: "Date",
      accessor: "created_at",
      id: "created_at",
      width: 110,
      minWidth: 110,
      Cell: ({ value }) => <p>{format(new Date(value), "PP")}</p>,
    },
    {
      Header: "First Name",
      accessor: "guest_first_name",
      id: "guest_first_name",
      minWidth: 30,
    },
    {
      Header: "Last Name",
      accessor: "guest_last_name",
      id: "guest_last_name",
      minWidth: 30,
    },
    {
      Header: "Phone",
      accessor: "guest_phone",
      id: "guest_phone",
      minWidth: 30,
    },
    {
      Header: "State",
      accessor: "guest_state",
      id: "guest_state",
      minWidth: 20,
    },
    {
      Header: "Zip",
      accessor: "guest_zip",
      id: "guest_zip",
      minWidth: 10,
    },
    {
      Header: "With Service?",
      accessor: "service",
      id: "service",
      minWidth: 40,
      Cell: ({ value }) => <p>{value ? "Yes" : "No"}</p>,
    },
    {
      Header: "Disposition",
      accessor: "disposition_type",
      id: "disposition_type",
      minWidth: 50,
    },
    {
      Header: "Estimate",
      accessor: "cost",
      id: "cost",
      minWidth: 70,
    },
  ],
  leadPartnerEstimates: [
    ...sharedEstimateColumns,
    {
      Header: "Urgency",
      accessor: "urgency",
      minWidth: 40,
    },
    {
      Header: "PreNeed Subject",
      accessor: "pre_need_subject",
      minWidth: 40,
      Cell: ({ value }) => <p>{value ? value : "n/a"}</p>,
    },
    {
      Header: "Source",
      accessor: "obit_url",
      minWidth: 40,
      Cell: ({ value, row: { original } }) => {
        return value ? (
          <a href={value} target="_blank" rel="noopener noreferrer">
            Obituary Page
          </a>
        ) : (
          "Business Page"
        );
      },
    },
  ],
  superAdminEstimates: [
    ...sharedEstimateColumns,
    {
      Header: "FH Id",
      accessor: "fh_id",
      minWidth: 40,
    },
    {
      Header: "Urgency",
      accessor: "urgency",
      minWidth: 40,
    },
    {
      Header: "PreNeed Subject",
      accessor: "pre_need_subject",
      minWidth: 40,
      Cell: ({ value }) => <p>{value ? value : "n/a"}</p>,
    },
    {
      Header: "Estate Planning",
      accessor: "estate_planning",
      minWidth: 40,
      Cell: ({ value }) => <p>{value ? "Yes" : "No"}</p>,
    },
    {
      Header: "Lead Partner",
      accessor: "lead_partner_name",
    },
    {
      Header: "Source",
      accessor: "obit_url",
      minWidth: 40,
      Cell: ({ value, row: { original } }) => {
        return value ? (
          <a href={value} target="_blank" rel="noopener noreferrer">
            Obituary Page
          </a>
        ) : (
          "Business Page"
        );
      },
    },
    {
      Header: "Notification Recipients",
      accessor: "notification_recipients",
    },
  ],
  fhSpotlightObits: [
    {
      Header: "Obituary Count ID",
      accessor: "fhls_obit_count_id",
      id: "fhls_obit_count_id",
      minWidth: 70,
      Cell: ({ value }) => (
        <Tooltip
          placement="right"
          overlay={<span>{value}</span>}
          arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
        >
          <p>
            {" "}
            {value.slice(0, 4)}...{value.slice(-6)}{" "}
          </p>
        </Tooltip>
      ),
    },
    {
      Header: "Year",
      accessor: "year",
      id: "year",
      minWidth: 30,
    },
    {
      Header: "Month",
      accessor: "month",
      id: "month",
      minWidth: 50,
      Cell: ({ value }) => `${matchMonth(value)}`,
    },
    {
      Header: "Free Obituary Count",
      accessor: "free_obit_count",
      id: "free_obit_count",
      minWidth: 40,
      Cell: InputEditableCell,
    },
    {
      Header: "Paid Obituary Count",
      accessor: "paid_obit_count",
      id: "paid_obit_count",
      minWidth: 40,
      Cell: InputEditableCell,
    },
    {
      Header: "",
      id: "utils",
      disableSortBy: true,
      minWidth: 40,
      align: "center",
      Cell: ({ row: { values, index }, remove, patch, details }) => (
        <Flex minWidth="90px">
          <Tooltip
            placement="bottom"
            overlay={<span>Update</span>}
            arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
          >
            <ButtonIcon
              onClick={(e) => {
                e.stopPropagation();
                patch(values);
              }}
              padding="10px 12px"
              margin="0 4px"
              kind={
                details && details.row_id === index ? "transparent" : "hidden"
              }
              changeIcon={<TiArrowSync />}
              defaultIcon={<TiArrowSync />}
              color={colors.GREEN_100}
              size="2rem"
            />
          </Tooltip>
          <Tooltip
            placement="bottom"
            overlay={<span>Delete Obituary Entry</span>}
            arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
          >
            <ButtonIcon
              onClick={(e) => {
                e.stopPropagation();
                remove(values);
              }}
              padding="12px 14px"
              margin="0 4px"
              kind="transparent delete"
              changeIcon={<BsTrashFill />}
              defaultIcon={<BsTrash />}
              color={colors.BLUE}
              size="1.6rem"
            />
          </Tooltip>
        </Flex>
      ),
    },
  ],
  superAdminLeadPartners: [
    {
      Header: "Lead Partner ID",
      accessor: "id",
      minWidth: 200,
    },
    {
      Header: "Lead Partner Name",
      accessor: "name",
      minWidth: 200,
    },
    {
      Header: '"View As" Lead Partner',
      id: "view-as",
      Cell: ({ row: { original }, setViewAs }) => {
        return (
          <Flex minWidth="80px">
            <Tooltip
              placement="bottom"
              overlay={<span>Click to view lead as this lead partner</span>}
              arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
            >
              <ButtonIcon
                onClick={(e) => {
                  e.stopPropagation();
                  setViewAs(original.id);
                }}
                padding="10px 12px"
                margin="0 4px"
                kind="transparent"
                defaultIcon={<MdPreview />}
                size="2rem"
              />
            </Tooltip>
          </Flex>
        );
      },
    },
  ],
  superAdminCorporations: [
    {
      Header: "Corporation ID",
      accessor: "id",
      minWidth: 300,
    },
    {
      Header: "Corporation Name",
      accessor: "corporation_name",
      minWidth: 300,
    },
  ],
};
